import styled from '@emotion/styled'
import React, { ReactNode, useEffect, useState } from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnOfficialBnr from '../atoms/columnOfficialBnr'
import ColumnSideNewList from '../molecules/columnSideNewList'
import AdsenseSide300x250 from '../atoms/adsenseSide300x250'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  background: #ffffff;
  margin: 0 auto;
  padding: 60px 0;
  width: 1080px;
  display: flex;
  justify-content: space-between;

  main {
    width: 100%;
    max-width: 700px;
  }

  aside {
    width: 300px;

    .ad-wrap {
      width: 300px;
      height: 250px;
      background: #cccccc;
      margin-bottom: 30px;
    }

    .fixed-wrap {
      position: sticky;
      top: 130px;

      #randomBanner {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  ${BreakPoints.large} {
    display: block;
    width: calc(100vw - 20px);

    main {
      padding: 0 10px;
    }

    aside {
      padding: 0 10px;
      width: calc(100% - 20px);

      .ad-wrap {
        margin: 0 auto 30px;
      }
    }
  }
`

type Props = {
  /** 子要素 */
  children: ReactNode
}

/**
 * コラム用メイン部分レイアウト_一覧画面用
 * @constructor
 */
const columnListBody = ({ children }: Props) => {
  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 8))
  }, [])

  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/lpa/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/lpb/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/lpa/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/lpb/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam5 = useRouteParam(
    'https://www.kintaicloud.jp/lpa/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam6 = useRouteParam(
    'https://www.kintaicloud.jp/lpb/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam7 = useRouteParam(
    'https://www.kintaicloud.jp/lpa/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam8 = useRouteParam(
    'https://www.kintaicloud.jp/lpb/?utm_source=column&utm_medium=side-banner&utm_campaign=2206&utm_content=list'
  )
  const routeParam9 = useRouteParam(
    'https://www.kintaicloud.jp/download/?utm_source=column&utm_medium=side_banner&utm_campaign=document_download&utm_id=banner'
  )

  return (
    <>
      <Wrapper>
        <main>{children}</main>
        <aside>
          <div className="fixed-wrap">
            <ColumnSideNewList />
            {no === 0 && (
              <div id="randomBanner">
                <a href={routeParam} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner001.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            {no === 1 && (
              <div id="randomBanner">
                <a href={routeParam2} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner002.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            {no === 2 && (
              <div id="randomBanner">
                <a href={routeParam3} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner001.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            {no === 3 && (
              <div id="randomBanner">
                <a href={routeParam4} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner002.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            {no === 4 && (
              <div id="randomBanner">
                <a href={routeParam5} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner001.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            {no === 5 && (
              <div id="randomBanner">
                <a href={routeParam6} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner002.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            {no === 6 && (
              <div id="randomBanner">
                <a href={routeParam7} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner001.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            {no === 7 && (
              <div id="randomBanner">
                <a href={routeParam8} target="_blank" rel="noreferrer">
                  <img
                    src="/images/column/common/sideLpBanner002.png"
                    alt="キンクラの導入効果"
                  />
                  導入効果
                </a>
              </div>
            )}
            <div className="download-bnr">
              <ColumnOfficialBnr
                href={routeParam9}
                target="_blank"
                imgId="sideBanner001.png"
                label="資料ダウンロード"
              />
            </div>
            {/** 広告挿入テスト：キンクラ_ディスプレイ_固定_サイドメニュー用_300x250 */}
            <AdsenseSide300x250 />
          </div>
        </aside>
      </Wrapper>
    </>
  )
}
export default columnListBody
