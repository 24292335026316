import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import ColumnAllLists from '../../components/molecules/columnAllLists'
import ColumnRecommendLists from '../../components/molecules/columnRecommendLists'
import ColumnLayout from '../../components/organisms/columnLayout'
import ColumnListBody from '../../components/organisms/columnListBody'

/**
 * コラム記事一覧画面
 * @constructor
 */
const ColumnListPage = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: '/column' },
    { title: 'コラム記事一覧' },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
          title="キンクラコラム記事一覧"
          description="勤怠管理や労働基準法の基礎知識から最近の法改正の解説まで。社会保険労務士の先生が執筆する、人事・総務担当者の「知りたい」が分かるお役立ちコラムを提供します。"
          ogUrl=" https://www.kintaicloud.jp/column/list/"
          ogType="article"
          ogTitle="キンクラコラム記事一覧 "
          ogDescription="勤怠管理や労働基準法の基礎知識から最近の法改正の解説まで。社会保険労務士の先生が執筆する、人事・総務担当者の「知りたい」が分かるお役立ちコラムを提供します。"
          ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
      />
      <ColumnListBody>
        {/** 全記事一覧（新しいものから20件くらいずつ） */}
        <ColumnAllLists />
        {/** 一覧ページング 静的ページのため一旦削除
        <ColumnPagination /> */}
        {/** おすすめ記事一覧 */}
        <ColumnRecommendLists />
      </ColumnListBody>
    </ColumnLayout>
  )
}

export default ColumnListPage

export const pageQuery = graphql`
  query ColumnListPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
